<template>
  <base-section id="about-our-product">
    <v-layout child-flexv-layout child-flex>
      <v-data-table
        :headers="headers"
        :items="models"
        item-key="reference"
        class="elevation-1"
        :search="search"
        :custom-filter="filterOnlyCapsText"
      >

        <template v-slot:item.erp="{ item }">
          <a v-if="item.erp !== undefined" :href="getReportUrl(item.reference)">
            <font-awesome-icon icon="download"/>
          </a>
          <p v-else />
        </template>


        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
      </v-data-table>
    </v-layout>
  </base-section>
</template>

<script>
export default {
  data() {
    return {
      search: ""
    };
  },

  created() {
    this.$store.dispatch("fetchReferences");
  },
  computed: {
    models() {
      return this.$store.getters.references;
    },
    headers() {
      return [
        {
          text: "Réf",
          align: "start",
          sortable: true,
          value: "reference"
        },
        {
          text: "Notice",
          sortable: true,
          value: "notice"
        },
        { text: 'P nominale', sortable: true, value: 'erp.p_nom' },
        { text: 'P min', sortable: true, value: 'erp.p_min' },
        { text: 'P max', sortable: true, value: 'erp.p_max' },
        { text: 'Conso nominal', sortable: true, value: 'erp.conso_nom' },
        { text: 'Conso min', sortable: true, value: 'erp.conso_min' },
        { text: 'Conso veille', sortable: true, value: 'erp.conso_sleep' },
        { text: 'autres', sortable: true, value: 'erp.extras' },
        { text: 'fiche ERP', sortable: false, value: 'erp' },
      ];
    }
  },
  methods: {

    getReportUrl(reference)
    {
      let base = process.env.NODE_ENV === "development"
          ? "http://localhost:3000"
          : "https://www.texasdefrance.com"
      return base + '/pdf/' + reference;
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    }
  }
};
</script>

<style scoped></style>
